<template>
  <div class="py-4">
    <div class="container-tight">
      <form
        class="card card-md"
        @submit.prevent="checkemail"
        autocomplete="off"
        v-if="showCheckEmailForm"
      >
        <div class="card-body" v-if="showEmailNotFound">
          <b-alert show variant="danger">
            Sorry, no account found for email address. Please call our Online
            Marketing Specialists at (866) 627-2467, or email your request to
            <a href="mailto:customerservice@mediabrains.com">
              customerservice@mediabrains.com
            </a>
            .
          </b-alert>
        </div>

        <div class="card-body">
          <h2 class="card-title text-center mb-4">
            Login to your company dashboard
          </h2>
          <div class="mb-3">
            <label class="form-label" for="emailInput">Email address</label>
            <input
              id="emailInput"
              type="email"
              v-model.trim="emailAddress"
              class="form-control"
              autocomplete="off"
            />
          </div>
          <div class="form-footer">
            <div class="d-flex justify-content-end">
              <div class="col-3">
                <button
                  type="submit"
                  :class="[
                    'btn',
                    'btn-primary',
                    'w-100',
                    canSubmitCheckEmail ? '' : 'disabled',
                  ]"
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>

          <router-link
            v-if="false"
            class="btn btn-outline-secondary"
            to="/add-your-company"
          >
            Get started with a company account
          </router-link>
        </div>
      </form>

      <form
        class="card card-md"
        @submit.prevent="login"
        autocomplete="off"
        v-else-if="!showForgotPasswordMsg"
      >
        <div class="card-body" v-if="showLoginError">
          <b-alert show variant="danger">
            Your password is incorrect. Please try logging in again or reset
            your password.
          </b-alert>
        </div>

        <div class="card-body">
          <small class="h6 d-flex justify-content-end">
            <a href="" @click.prevent="goback">{{ this.notyoumsg }}</a>
          </small>
          <h2 class="card-title text-center mb-4">{{ this.welcomemsg }}</h2>
          <div class="mb-3">
            <label class="form-label" for="emailInput">Email address</label>

            <div style="margin: 0 auto auto">
              <span class="form-control">{{ emailAddress }}</span>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label" for="passwordInput">
              Password
              <span class="form-label-description">
                <a tabindex="-1" @click.prevent="forgotpasswordclick" href="">
                  Forgot password?
                </a>
              </span>
            </label>
            <input
              id="passwordInput"
              type="password"
              v-model="password"
              class="form-control"
              autocomplete="off"
            />
          </div>
          <div class="form-footer">
            <button
              type="submit"
              :class="[
                'btn',
                'btn-primary',
                'w-100',
                canSubmit ? '' : 'disabled',
              ]"
              @click="setIsLoading"
            >
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                v-if="this.isLoading"
              ></span>
              Sign in
            </button>
          </div>

          <div v-if="showBottomHalf">
            <div class="separator form-label">Or</div>

            <div class="text-center" v-if="!showLoginLinkSent">
              <a href="" @click.prevent="sendloginlink">
                Send a login link to my email
              </a>
            </div>
            <div class="card-body check-email" v-else>
              <div class="d-flex justify-content-center">
                <!-- Download SVG icon from http://tabler-icons.io/i/circle-check -->
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12" cy="12" r="9" /><path d="M9 12l2 2l4 -4" /></svg> -->

                <span class="bg-green text-white avatar me-2 mb-2">
                  <!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-circle-check"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <path d="M9 12l2 2l4 -4"></path>
                  </svg>
                </span>
              </div>

              <div class="text-center font-weight-bold mt-2">
                Check your email for your sign in link.
              </div>
            </div>
          </div>
        </div>
      </form>

      <form class="card card-md" v-if="showForgotPasswordMsg">
        <div class="card-body text-center">
          <div class="font-weight-bold mt-1">
            We will send an email to {{ this.emailAddress }} with password reset
            instructions.
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 40px 0px 20px 0px;
  margin-bottom: 0px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid rgba(101, 109, 119, 0.16) !important;
}

.separator:not(:empty)::before {
  margin-right: 25px;
}

.separator:not(:empty)::after {
  margin-left: 25px;
}

.check-email {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>

<script>
import TrackingService from "../services/TrackingService";

export default {
  name: "Login",
  data: () => {
    return {
      emailAddress: "",
      password: "",
      userid: 0,
      emailnotfound: false,
      checkemailform: true,
      forgotpassword: false,
      loginlinksent: false,
      bottomhalf: true,
      isLoading: false,
      loginerror: false,
      firstname: "",
      welcomemsg: "",
      notyoumsg: "",
    };
  },
  methods: {
    async setUp() {
      var email = this.$route.query.email;

      if (email) {
        this.emailAddress = email;
        this.checkemail();
      }
    },

    async goback() {
      this.checkemailform = true;
    },

    async forgotpasswordclick() {
      let result = await this.$auth.sendresetemail(this.emailAddress);
      TrackingService.track("request forgot password link");

      if (result) {
        this.forgotpassword = true;
        this.bottomhalf = false;
      }
    },

    async sendloginlink() {
      let result = await this.$auth.sendloginlink(
        this.emailAddress,
        this.userid
      );

      TrackingService.track("request one-click login link");

      if (result) {
        this.loginlinksent = true;
      }
    },

    async checkemail() {
      // reset
      this.emailnotfound = false;
      this.forgotpassword = false;
      this.loginlinksent = false;
      this.loginerror = false;
      this.bottomhalf = true;
      this.firstname = "";

      let checkEmailResult = await this.$auth.checkemail(this.emailAddress);

      if (checkEmailResult) {
        let userinfo = this.$auth.userinfo;

        if (userinfo) {
          // email found, show full form
          this.emailnotfound = false;
          this.checkemailform = false;
          this.userid = userinfo.user.userId;
          this.firstname =
            userinfo.contact.firstName.substring(0, 1).toUpperCase() +
            userinfo.contact.firstName.substring(1);

          if (this.firstname) {
            this.welcomemsg = "Welcome, " + this.firstname;
            this.notyoumsg = "Not " + this.firstname + "?";
          } else {
            this.welcomemsg = "Welcome";
            this.notyoumsg = "Wrong email?";
          }
          TrackingService.track("login email found");
        } else {
          // email not found, keep email form
          this.emailnotfound = true;
          this.checkemailform = true;
        }
      }
    },

    async setIsLoading() {
      this.isLoading = true;
    },

    async login() {
      let loginResult = await this.$auth.login(
        this.emailAddress,
        this.password
      );
      if (loginResult) {
        await this.$auth.clearCache();
        TrackingService.track("login success");
        this.$router.push("/");
      } else {
        this.loginerror = true;
        this.password = "";
        this.isLoading = false;
        TrackingService.track("login failed incorrect password");
      }
    },
  },
  computed: {
    canSubmit() {
      return this.emailAddress.length > 0 && this.password.length > 0;
    },
    canSubmitCheckEmail() {
      return this.emailAddress.length > 0;
    },
    showCheckEmailForm() {
      return this.checkemailform;
    },
    showEmailNotFound() {
      return this.emailnotfound;
    },
    showForgotPasswordMsg() {
      return this.forgotpassword;
    },
    showLoginLinkSent() {
      return this.loginlinksent;
    },
    showBottomHalf() {
      return this.bottomhalf;
    },
    showLoginError() {
      return this.loginerror;
    },
  },
  async mounted() {
    this.setUp();
    TrackingService.pageUnauthenticated(this.$router.name);
  },
};
</script>
